import { ServiceFactory } from '@/services/ServiceFactory'
const _clientSearch = ServiceFactory.get('ClientSearchService')
const _businessService = ServiceFactory.get('BarsService')
export default {
  data () {
    return {
      search: '',
      business: [],
      discounts: [
        { text: 'Descuento', value: null },
        { text: '10%', value: 0.1 },
        { text: '15%', value: 0.15 },
        { text: '20%', value: 0.2 },
        { text: '25%', value: 0.25 },
        { text: '30%', value: 0.3 },
        { text: '35%', value: 0.35 },
        { text: '40%', value: 0.4 },
        { text: '45%', value: 0.45 },
        { text: '50%', value: 0.5 }
      ],
      dates: [],
      menu: false,
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Email', sortable: false, value: 'email' },
        { text: 'Celular', sortable: false, value: 'cellphone' },
        { text: 'Total', sortable: false, value: 'total' },
        { text: 'Verif.', sortable: false, value: 'completed' },
        { text: 'Activ.', sortable: false, value: 'active' },
        { text: 'Cancel.', sortable: false, value: 'cancelled' },
        { text: 'No asist.', sortable: false, value: 'noShow' },
        { text: 'Activo desde', sortable: false, value: 'createdAt' },
        { text: 'Opciones', sortable: false, value: 'options' }
      ],
      items: [],
      businessIds: '',
      discount: null,
      loading: false,
      loadingBusiness: false,
      page: 1,
      pageCount: 0,
      totalItems: 0,
      searchType: ''
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        this.loadingBusiness = true
        const businessResp = await _businessService.listBar()
        console.log(businessResp)
        this.business = businessResp.bars
        this.loadingBusiness = false
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Ocurrió un error al obtener la lista de restaurantes, intente nuevamente. ' + error.msg
        })
        this.loadingBusiness = false
      }
    },
    clearMessage () {
      this.items = []
      this.searchType = ''
      this.page = 1
      this.totalItems = 0
      this.pageCount = 0
    },
    async searchUser () {
      this.page = 1
      this.businessIds = ''
      this.discount = null
      this.dates = []
      this.searchByName()
    },
    async searchByName () {
      // alert(this.search)
      this.searchType = 'search'
      try {
        this.loading = true
        const searchResp = await _clientSearch.searchUsers(this.search, this.page)
        console.log(searchResp)
        this.items = searchResp.clients
        this.totalItems = searchResp.total
        this.pageCount = Math.ceil(this.totalItems / 50)
        this.$notify({
          type: 'success',
          text: searchResp.msg
        })
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Ocurrió un error al obtener la lista de clientes, intente nuevamente. ' + error.msg
        })
        this.loading = false
      }
    },
    history (id) {
      this.$router.push({ name: 'history', params: { id } })
    },
    clearDates () {
      this.dates = []
      this.getClients()
    },
    async getClients () {
      this.page = 1
      this.search = ''
      this.searchByFilter()
    },
    async searchByFilter () {
      // console.log(this.businessIds, this.discount, this.dates)
      console.log(this.dates)
      this.searchType = 'filter'
      try {
        this.loading = true
        if (this.businessIds.length > 0 || this.discount || this.dates.length > 1) {
          const clientsResp = await _clientSearch.feedbackFilter(this.businessIds, this.discount, this.dates, this.page)
          console.log(clientsResp)
          this.items = clientsResp.clients
          this.totalItems = clientsResp.total
          this.pageCount = Math.ceil(this.totalItems / 50)
          this.$notify({
            type: 'success',
            text: clientsResp.msg
          })
        } else {
          this.items = []
          this.searchType = ''
          this.pageCount = 0
          this.totalItems = 0
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Ocurrió un error al obtener la lista de clientes, intente nuevamente. ' + error.msg
        })
        this.loading = false
      }
    },
    previous () {
      // alert('prev')
      if (this.searchType === 'filter') {
        this.searchByFilter()
      } else {
        this.searchByName()
      }
    },
    next () {
      // alert('next')
      if (this.searchType === 'filter') {
        this.searchByFilter()
      } else {
        this.searchByName()
      }
    },
    toPage (p) {
      // alert(p)
      if (this.searchType === 'filter') {
        this.searchByFilter()
      } else {
        this.searchByName()
      }
    },
    selectedBusiness () {
      var bn = '| '
      this.businessIds.map((el) => {
        var res = this.business.find(({ zbBarId }) => zbBarId === el)
        bn = bn + res.name + ' |'
      })
      console.log(bn)
      return bn
    }
  }
}
